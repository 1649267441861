
import { gyData, jwData, itemData } from "@/utils/simulation";
import moment from "moment";
import Api from "@/assets/api/api";
import DatePicker from "@/components/DatePicker/index.vue";
import MySelect from "@/components/MySelect/index.vue";
import Warm from "./components/Warm.vue";
import { useStore } from "vuex";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "",
  components: { MySelect, Warm, DatePicker },
  setup() {
    const store = useStore();
    const data = reactive({
      tabPosition: "day",
      value: 480,
      timeLineCodes: [],
      coolingData: <any>{},
      marks: <any>{
        // 0: "00:00",
        // 840: {
        //   style: {
        //     color: "#1989FA",
        //     // backgroundColor: "#fff",
        //   },
        //   label: "14:00",
        // },
        // 720: {
        //   style: {
        //     color: "#1989FA",
        //   },
        //   label: "12:00",
        // },
      },
      Time: <any>"",
      AllData: <any>[],
      curveItemBatchCode: "",
      curveDateMinutes: <any>"",
      coolingShow: true,
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      // 获取今日时间
      data.Time = moment(new Date()).format("YYYY-MM-DD");
      //3.组件挂载到页面之后执行-------onMounted
      // 获取左侧工件序列
      getTime();
      // 获取工艺参数
      getByPage();

      getTemperatureCurve(); //模拟数据 需要注释
      TimerShaft(); // 模拟数据 需要注释
    });
    const formatTooltip = (val: any) => {
      let hour = <any>0;
      let min = <any>0;
      let num = <any>60;
      // hour = parseInt(val / 60);
      hour = val / num;
      hour = parseInt(hour);
      if (hour < 10) {
        hour = "0" + hour.toString();
      } else {
        hour = hour.toString();
      }
      min = val - hour * 60;
      if (min < 10) {
        min = "0" + min.toString();
      } else {
        min = min.toString();
      }
      let time = hour + ":" + min;
      return time;
    };
    // 获取时间轴承
    const TimerShaft = () => {
      let res = <any>itemData;
      data.timeLineCodes = res.data.timeLineCodes;
      if (res.data.timeLineCodes.length) {
        data.curveItemBatchCode = res.data.timeLineCodes[0].curveItemBatchCode;
      }
      // 获取降温曲线折线图
      setTimeout(() => {
        getTemperatureCurve();
      }, 100);

      // Api.commonPost(
      //   "craft",
      //   "TimerShaft",
      //   {
      //     curveDateMinutes: data.curveDateMinutes,
      //     curveDateYmd: data.Time,
      //     produceItemCode: 100,
      //     projectId: store.state.projectId,
      //   },
      //   (res: any) => {
      //     data.timeLineCodes = res.data.timeLineCodes;
      //     if (res.data.timeLineCodes.length) {
      //       data.curveItemBatchCode =
      //         res.data.timeLineCodes[0].curveItemBatchCode;
      //     }
      //     // 获取降温曲线折线图
      //     setTimeout(() => {
      //       getTemperatureCurve();
      //     }, 100);
      //   },
      //   (err: any) => {
      //     ElMessage.error(err.msg);
      //   }
      // );
    };
    // 获取左侧工件序列
    const getTime = () => {
      Api.commonPost(
        "craft",
        "getTime",
        {
          produceItemCode: 100,
          selectDate: data.Time,
          projectId: store.state.projectId,
        },
        (res: any) => {
          let Arr = [];
          let Obj = <any>{};
          res.data.forEach((item: any, index: any) => {
            Arr = item.split(":");
            let num = 0;
            Arr.forEach((it: any, i: any) => {
              if (i == 0) {
                num = it * 60;
              }
              if (i == 1) {
                num += +it;
              }
            });
            Obj[(num += index)] = {
              style: {
                color: "#1989FA",
              },
              // label: str,
            };
          });
          data.marks = Obj;
          data.curveDateMinutes = res.data[0];
          if (res.data.length) {
            // 获取时间轴承
            setTimeout(() => {
              TimerShaft();
            }, 100);
          } else {
            // data.timeLineCodes = [];
            // data.coolingShow = false;
            console.log("进来了", data.coolingShow);
          }
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };
    // 工艺数据切换
    const getCut = (Data: any) => {
      data.curveItemBatchCode = Data.curveItemBatchCode;
      data.Time = Data.curveDate;
      getByPage(); // 重新获取Bypage
      getTemperatureCurve(); // 重新获取降温曲线
    };
    // 降温曲线
    const getTemperatureCurve = () => {
      let res = jwData;
      data.coolingData = res;
      if (res.data.lines.length) {
        data.coolingShow = true;
      } else data.coolingShow = false;

      // Api.commonPost(
      //   "craft",
      //   "temperatureCurve",
      //   {
      //     curveItemBatchCode: data.curveItemBatchCode,
      //     produceItemCode: 100,
      //     projectId: store.state.projectId,
      //   },
      //   (res: any) => {
      //     data.coolingData = res;
      //     if (res.data.lines.length) {
      //       data.coolingShow = true;
      //     } else data.coolingShow = false;
      //   },
      //   (err: any) => {
      //     ElMessage.error(err.msg);
      //   }
      // );
    };
    // 获取工艺参数
    const getByPage = () => {
      data.AllData = gyData.data;
      // Api.commonPost(
      //   "dap",
      //   "byPage",
      //   {
      //     pageId: 100003,
      //     projectId: store.state.projectId,
      //     selectTime: data.Time,
      //   },
      //   (res: any) => {
      //     console.log(res.data);
      //     data.AllData = res.data;
      //   },
      //   (err: any) => {
      //     ElMessage.error(err.msg);
      //   }
      // );
    };
    // 获取时间
    const getTimeValue = (value: any) => {
      data.Time = moment(value).format("YYYY-MM-DD");
      getTime();
      getByPage(); // 重新获取Bypage
    };
    // 时间滑块
    const getSlider = (e: any) => {
      console.log(e);
    };
    return {
      ...toRefs(data),
      formatTooltip,
      getTimeValue,
      getSlider,
      getCut,
    };
  },
});
