
import { Line } from "@/assets/echarts";
import * as echarts from "echarts";
import { da } from "element-plus/es/locale";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    timeLineCodes: {
      type: Array,
    },
    coolingData: {
      type: Object,
    },
    AllData: {
      type: Object,
    },
    coolingShow: {
      type: Boolean,
    },
  },
  setup(props, cxt) {
    const data = reactive({
      list: <any>[],
      LineData: <any>{},
      LineData2: <any>{},
      heatingFurnace: [
        {
          id: 1,
          name: "步进间隔:",
          value: "15",
          kind: "秒",
        },
        {
          id: 2,
          name: "T1设定温度:",
          value: "200",
          kind: "°C",

          name2: "T2设定温度:",
          value2: "200",
          kind2: "°C",
        },
        {
          id: 3,
          name: "T1设定温度:",
          value: "200",
          kind: "°C",

          name2: "T2设定温度:",
          value2: "200",
          kind2: "°C",
        },
        {
          id: 3,
          name: "T1设定温度:",
          value: "200",
          kind: "°C",

          name2: "T2设定温度:",
          value2: "200",
          kind2: "°C",
        },
        {
          id: 3,
          name: "T1设定温度:",
          value: "200",
          kind: "°C",

          name2: "T2设定温度:",
          value2: "200",
          kind2: "°C",
        },
        {
          id: 3,
          name: "T1设定温度:",
          value: "200",
          kind: "°C",

          name2: "T2设定温度:",
          value2: "200",
          kind2: "°C",
        },
        {
          id: 3,
          name: "T1设定温度:",
          value: "200",
          kind: "°C",

          name2: "T2设定温度:",
          value2: "200",
          kind2: "°C",
        },
        {
          id: 3,
          name: "T1设定温度:",
          value: "200",
          kind: "°C",

          name2: "T2设定温度:",
          value2: "200",
          kind2: "°C",
        },
        {
          id: 3,
          name: "T1设定温度:",
          value: "200",
          kind: "°C",

          name2: "T2设定温度:",
          value2: "200",
          kind2: "°C",
        },
      ],
      craftData: <any>[],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      data.LineData = <any>Line().option;
      data.LineData2 = <any>Line().option;
      getalter(props);
      //3.组件挂载到页面之后执行-------onMounted
      getLine();
    });
    // 监听父组件传递过来的值
    watch(
      props,
      (newValue) => {
        getalter(newValue);
      },
      { deep: true }
    );

    // props 的值改变调用的方法
    const getalter = (Data: any) => {
      Data.timeLineCodes?.forEach((item: any, i: any) => {
        if (i == 0) {
          item.active = true;
        }
      });
      data.list = Data.timeLineCodes;
      console.log(data.list);

      // 降温曲线数据
      data.LineData2.series = [];
      data.LineData2.legend.data = [];
      data.LineData2.xAxis.data = [];

      if (Data.coolingData.data != undefined) {
        Data.coolingData.data.lines.forEach((item: any) => {
          data.LineData2.series.push({
            name: item.curveTitle,
            data: item.pointDtoList.map((it: any) => it.value),
            type: "line",
            smooth: true,
          });
          data.LineData2.xAxis.data = item.pointDtoList.map(
            (it: any) => it.name
          );
          data.LineData2.legend.data.push(item.curveTitle);
        });
        setTimeout(() => {
          getLine2();
        }, 100);
      }

      // 工艺参数区域
      data.craftData = props.AllData?.pageTabs;
      if (data.craftData != undefined) {
        let warmData = data.craftData[1].dataValues[2].dataItemValues;
        let warmData2 = data.craftData[1].dataValues[1].dataItemValues;
        data.heatingFurnace = warmData2;
        data.LineData.series[0].data = warmData.map((item: any) => item.value);
        data.LineData.series[0].name = "运行温度";
        data.LineData.series[1].data = warmData2.map((item: any) => item.value);
        data.LineData.series[1].name = "设定温度";
        data.LineData.legend.data = ["运行温度", "设定温度"];
        data.LineData.xAxis.data = warmData.map((item: any) => item.title);
      }
      getLine();
    };
    const getCut = (Data: any) => {
      data.list.filter((item: any) =>
        item.id == Data.id ? (item.active = true) : (item.active = false)
      );
      cxt.emit("getCut", Data);
    };
    const line = <any>ref();
    const line2 = <any>ref();
    const getLine = () => {
      let myChart = echarts.init(line._value);
      myChart.setOption(data.LineData);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    };
    const getLine2 = () => {
      console.log(line2);
      let myChart = echarts.init(line2._value);
      myChart.setOption(data.LineData2);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    };
    return {
      ...toRefs(data),
      line,
      line2,
      getCut,
    };
  },
});
