
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  watch,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    Time: {
      type: String,
    },
  },
  setup(props, ctx) {
    const data = reactive({
      value1: <any>"",
    });
    watch(
      props,
      (newValue) => {
        data.value1 = newValue.Time;
      },
      { deep: true }
    );
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });

    const getTime = (e: any) => {
      ctx.emit("getTimeValue", e);
    };
    return {
      ...toRefs(data),
      getTime,
    };
  },
});
